:root {
  height: 100%;
  /* Primary colors */
  --primary-color: #5479f7;
  --primary-color-hover: #1d47d7;
  --primary-color-background-hover: #ecf1ff;
  --primary-dark: #212131;
  --primary-color-dark: #809cf8;
  --primary-color-active: #313146;
  --primary--color-medium: #f1f5ff;
  --primary--color-drawer: #212131;
  --secondary--color-drawer: #303040;
  --secondary--color-selected: #4b4b65;

  /* Neutral colors */
  --neutral-color-dark: #4e5968;
  --neutral-color-medium: #718096;
  --neutral-color-light: #8692a3;
  --neutral-color-strokes: #eaeaea;
  --neutral-color-background: #f7f8f8;
  --neutral-color-background-darker: #eff1f3;
  --neutral-color-white: #ffffff;
  --neutral-color-black: #000000;

  /* Status colors */
  --status-succes-light: #f8ffee;
  --status-succes-contrast: #72c140;
  --status-error-light: #fff0f2;
  --status-error-contrast: #ff9393;
  --status-warning-light: #fef6e8;
  --status-warning-contrast: #efb140;
  --status-informational-light: #f1f5ff;
  --status-informational-contrast: #5479f7;
}

* {
  box-sizing: border-box;
}
html,
body,
#root {
  overflow-y: hidden !important;
  height: 100vh;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

body root {
  height: 100%;
}

/* text style guide  */

body h1 {
  font-size: 22px;
  font-weight: bold;
}
body h2 {
  font-size: 20px;
  font-weight: bold;
}

body h3 {
  font-weight: bold;
  font-size: 1rem;
}
body p {
  font-size: 1rem;
  color: var(--neutral-color-light);
}

body label {
  color: var(--neutral-color-light);
  font-size: 14px;
  text-transform: uppercase;
}

.info-text {
  font-size: 14px !important;
}
.secondary-info-text {
  font-size: 12px !important;
}

@media (max-width: 1199px) {
  .search-header-container {
    margin-left: 0px;
  }
}
/* Buttons */
.ant-btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.bkl-button-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

/* Our typografy */

.typo-medium {
  font-family: "Lato";
  font-size: 16px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

/* Ant Design styles */

.custom-breadcrumb .ant-breadcrumb-link:hover {
  color: var(--primary-color);
}

.ant-breadcrumb a {
  margin-inline: 4px !important;
  padding: 0px;
}
.ant-breadcrumb li {
  margin-inline: 4px !important;
}

.ant-breadcrumb a:hover {
  color: var(--primary-color);
  background-color: white;
  max-width: none;
}

/* Layout */

.ant-layout-content {
  overflow-y: auto;
  padding: 16px;
  margin: 0px;
}

.ant-layout {
  height: 100%;
  background-color: var(--neutral-color-white);
}

.ant-layout-header {
  right: 70px;
  position: absolute;
  top: 5px;
  width: 100px;
  padding: 0px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
}

.ant-card-body {
  padding: 0 !important;
  border-radius: 0 !important;
}

.ant-card-head {
  padding: 24px !important;
}

.ant-layout-content {
  /* border-radius: 12px; */
}

.ant-drawer-content-wrapper {
  margin-top: 0;
}

@media (max-width: 767px) {
  .ant-drawer-content-wrapper {
    margin-top: 65px;
  }
}

.ant-drawer-header {
  background-color: var(--primary--color-drawer);
}

.ant-menu-inline {
  border: none !important;
}

.ant-layout-sider-children {
  background-color: var(--primary--color-drawer);
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  background-color: var(--primary--color-drawer);
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: var(--primary-color-dark);
}

.ant-menu-light.ant-menu-submenu > .ant-menu {
  background-color: var(--primary--color-drawer);
}

.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item {
  color: white !important;
}

.ant-menu-item {
  color: var(--neutral-color-white) !important;
}

.ant-menu-submenu-title {
  color: var(--neutral-color-white) !important;
}

.ant-menu-item-selected {
  background-color: var(--primary-color-active) !important;
  color: var(--primary-color-dark) !important;
}

.ant-menu-item:hover,
.ant-menu-item:not(.ant-menu-item-selected):hover,
.ant-menu-submenu-title:hover {
  color: var(--primary-color-dark) !important;
  background-color: var(--primary-color-active) !important;
}

.ant-drawer-title {
  color: white !important;
}

.ant-drawer-close {
  color: white !important;
}

/* General Clases */

.standard-background-card {
  background-color: var(--neutral-color-background);
  padding: 24px;
}

.card-animated-hover {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.card-animated-hover:hover {
  transform: translateY(-1px) scale(1);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}
.card-darken-hover {
  cursor: pointer;
  position: relative; /* Asegúrate de que el ::after se posicione correctamente */
  border: 2px solid transparent; /* Borde transparente inicialmente */
  transition: border-color 0.2s ease, background-color 0.2s ease;
}

.card-darken-hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;
  border-radius: inherit;
  z-index: -1;
}

.card-darken-hover:hover {
  border-color: var(--primary-color);
  background-color: var(--primary-color-background-hover);
}

.card-darken-hover:hover::after {
  opacity: 0.1;
}

/* Togglers */

.ant-switch.ant-switch-checked {
  background: var(--primary-color);
}

/* Inputs */

.ant-select-selector {
  min-width: 100px;
}

/* React country citys inputs */

.stdropdown-container {
  border: none !important;
}

.stdropdown-input {
  padding: 0px !important;
}

.stdropdown-tools {
  display: none !important;
}
